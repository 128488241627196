






























































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { Action } from 'vuex-class'
import {
  GET_OPEN_ACTIVATION_REQUESTS_COMPANIES,
  GET_OPEN_ACTIVATION_REQUESTS_TALENTS
} from '@/store/employee/actions.type'
import { TalentProfileViewType } from '@/store/types'
import { CompanyProfile } from '@/store/company/types'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import { FilteringType, SortingInfo } from '@/store/commonFormInput/types'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { KodeEvaluationTalentData } from '@/store/kode/types'

@Component({
  components: { SearchResult, ResultsPage, LoadingSpinner }
})
export default class ProfileActivations extends Vue {
  @Action(GET_OPEN_ACTIVATION_REQUESTS_TALENTS)
  public getOpenActivationRequestsTalents: () => Promise<Array<TalentProfileViewType>>

  @Action(GET_OPEN_ACTIVATION_REQUESTS_COMPANIES)
  public getOpenActivationRequestsCompanies: () => Promise<Array<CompanyProfile>>

  loading = false
  requestsTalents: TalentProfileViewType[] = []
  requestsCompanies: CompanyProfile[] = []

  mounted (): void {
    this.loadRequests()
  }

  loadRequests (): void {
    this.loading = true
    Promise.all([
      this.getOpenActivationRequestsTalents(),
      this.getOpenActivationRequestsCompanies()
    ]).then((data) => {
      this.requestsTalents = data[0]
      this.requestsCompanies = data[1]
    }).finally(() => {
      this.loading = false
    })
  }

  getFullName (talent: KodeEvaluationTalentData): string {
    return `${talent.salutation && talent.salutation !== 'EMPTY_DUMMY' ? this.$t(`personal-information.SALUTATIONS.${talent.salutation}`) : ''} ${talent.firstname} ${talent.lastname}`
  }

  get sortableTalent (): SortingInfo[] {
    return [
      { key: 'firstname', label: this.$i18n.t('personal-information.firstName').toString() },
      { key: 'lastname', label: this.$i18n.t('personal-information.lastName').toString() },
      { key: 'email', label: this.$i18n.t('personal-information.email').toString() }
    ]
  }

  get filterKeysTalent (): FilteringType[] {
    return this.sortableTalent
  }

  get sortableCompany (): SortingInfo[] {
    return [
      { key: 'companyName', label: this.$i18n.t('profile-validation.sorting.company-name').toString() },
      { key: 'contactEmail', label: this.$i18n.t('profile-validation.sorting.contact-mail').toString() },
      { key: 'username', label: this.$i18n.t('profile-validation.sorting.account-mail').toString() }
    ]
  }

  get filterKeysCompany (): FilteringType[] {
    return this.sortableCompany
  }

  get adminPanelRight (): boolean {
    return hasRight(Rights.ADMIN_PANEL)
  }
}
